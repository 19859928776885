<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
      thead
        tr
          th(rowspan=2) {{ $t("ordinal_number") }}
          th(rowspan=2) {{ $t("version") }}
          th(rowspan=2) {{ $t("accessory_frame_color") }}
          th(rowspan=2) {{ $t("mosquito_net.global_net") }}
          th(rowspan=2 v-if="printType === 'full'") {{ $t("width", { lengthUnit }) }}
          th(rowspan=2 v-if="printType === 'full'") {{ $t("height", { lengthUnit }) }}
          th(rowspan=2) {{ $t("pieces") }}
          th(rowspan=2) {{ $t("additional") }}
          th.has-text-right(rowspan=2 v-if="priceType !== 'sales' && printType === 'full'") {{ $t("net_purchase_value", { currencyCode }) }}
          th.has-text-right(rowspan=2 v-if="priceType !== 'purchase' && printType === 'full'") {{ $t("net_sales_value", { currencyCode }) }}
      tbody
        template(v-for="(item, index) in rollMosquitoNetOrderItems")
          roll-mosquito-net-order-item(
            :item="item"
            :index="index"
            :price-type="priceType"
            :print-type="printType"
          )
      tr
        td(:colspan="printType === 'full' ? 8  : 5" style="border: 0;")
        td(v-if="priceType === 'full'") {{ $t('sum_up') }}
        td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netPurchaseValue"))}}
        td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netSalesValue")) }}
</template>

<script>
import {mapGetters} from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "@/helpers/priceFormatter";
import RollMosquitoNetOrderItem from "./RollMosquitoNetOrderItem.vue";

export default {
  components: { RollMosquitoNetOrderItem},
  props: {
    rollMosquitoNetOrderItems: {type: Array, default: () => [], required: true},
    priceType: {default: null, required: true},
    printType: {default: null, required: true}
  },
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
  },
  methods: {
    sumValueByParameterInOrderItems: function(parameter)  {
      return this.rollMosquitoNetOrderItems.reduce((total, item) => (item[parameter]) + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
