import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import rollMosquitoNet from "./store";

store.registerModule("rollMosquitoNets", rollMosquitoNet);
router.addRoutes([
  {
    path: "/roll_mosquito_net/create_order/:copyId?",
    name: "roll_mosquito_net_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/roll_mosquito_net/edit_order/:id",
    name: "roll_mosquito_net_edit_order",
    component: CreateOrder,
    props: true
  }
]);
