<template lang="pug">
  div(v-if="isLoggedIn" class="is-fullheight is-100vh user-color-theme" :style="colorTheme")
    b-loading(:active="loading" v-if="loading")
    template(v-else-if="regulationReadAt")
      Navbar
      div.columns.is-fullheight
        div.column.is-2.has-padding-top-small.has-padding-left-small.has-border-radius
          Sidebar(v-if="!loading")
        div.column.is-10.has-padding-right-small
          .box.has-border-radius
            router-view(:key="$route.name")
    template(v-else)
      Regulation
  div(v-else :style="backgroundImage")
    router-view
</template>
<script>
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Regulation from "./modules/common/components/Regulation";
import {mapGetters} from "vuex";

export default {
  components: {
    Sidebar,
    Navbar,
    Regulation
  },
  computed: {
    ...mapGetters("common", ["loading"]),
    ...mapGetters("company", ["regulationReadAt", "hasProductType"]),
    ...mapGetters("auth", ["userType", "companyType", "hasPermission"]),
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    ...mapGetters("auth", ["userId"]),
    ...mapGetters(["themeBackgroundColor", "pageLoginBackgroundImage"]),
    colorTheme() {
      if (this.themeBackgroundColor) {
        return {
          "--user-bg-color": this.themeBackgroundColor
        };
      }

      return {
        "--user-bg-color": "white"
      };
    },
    backgroundImage() {
      if (this.pageLoginBackgroundImage) {
        return {
          "background-image": `url("${process.env.VUE_APP_API_URL}media/${this.pageLoginBackgroundImage}")`,
          "background-repeat": "no-repeat",
          "background-position": "center center",
          "background-size": "cover",
        };
      }

      return {
        "background-image": "none"
      };
    }
  },
  methods: {
    /**
     * Initialize data
     * @param isLoggedIn
     * @returns {Promise<void>}
     */
    async init(isLoggedIn) {
      this.$store.dispatch("getBackground") // fetch background image
      if (isLoggedIn) {
        await this.$store.commit("common/startLoading");
        if (this.hasPermission("NEWS_READ")) await this.$store.dispatch("news/init"); // wait for news to be fetched and block app
        if (this.hasPermission("COMPANY_READ")) await this.$store.dispatch("company/init"); // wait for company to be fetched and block app
        if (this.hasPermission("CONFIGURATION_READ")) await this.$store.dispatch("configuration/init");  // wait for news to be fetched and block app
        if (this.hasPermission("FABRIC_SHADE_READ") && this.hasProductType('RTK')) this.$store.dispatch("fabricShades/init");
        if (this.hasPermission("FABRIC_SHADE_DAY_NIGHT_READ") && this.hasProductType('RDN')) this.$store.dispatch("fabricShadeDayNights/init");
        if (this.hasPermission("EXTERNAL_SHUTTER_READ") && this.hasProductType('RZW')) this.$store.dispatch("externalShutters/init");
        if (this.hasPermission("PLEAT_READ") && this.hasProductType('PLI')) this.$store.dispatch("pleats/init");
        if (this.hasPermission("AUTOMATION_READ") && this.hasProductType('AUT')) this.$store.dispatch("automation/init");
        if(this.hasPermission("FRAME_MOSQUITO_NET_READ"))this.$store.dispatch("frameMosquitoNets/init");
        if(this.hasPermission("ROLL_MOSQUITO_NET_READ"))this.$store.dispatch("rollMosquitoNets/init");
        // this.$store.dispatch("ordersList/fetch");
        await this.$store.commit("common/endLoading");
      }
    }
    // async fetchRetailPriceList(isLoggedIn) {
    //   if (isLoggedIn) {
    //     this.loading = true;
    //     await this.$store.dispatch("auth/fetchRetailPriceList");
    //     this.loading = false;
    //   }
    // }
  },
  watch: {
    isLoggedIn(value) {
      this.init(value);
      // this.fetchRetailPriceList(value);
    }
  },
  created() {
    this.init(this.isLoggedIn);
    if (this.userId) {
      this.$store.dispatch("init", this.userId);
    }
    // this.fetchRetailPriceList(this.isLoggedIn);
  }
};
</script>
