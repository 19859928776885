<template lang="pug">
  div
    .columns.is-desktop
      .column.is-6-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form
          .columns
            .column
              b-field(grouped)
                app-form-field(:label="$t('version')" field="rollMosquitoNet" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="rollMosquitoNets"
                      label="name"
                      v-model="item.rollMosquitoNet"
                      :clearable="false"
                      @input="onRollMosquitoNetChanged"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.rollMosquitoNet, 'picture')"
                      @open="openImage($event)"
                    )

              app-form-field(:label="$t('accessory_frame_color')" field="rollMosquitoNetGlobalAccessoryEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemRollMosquitoNetGlobalAccessoryEntries"
                    label="name"
                    v-model="item.rollMosquitoNetGlobalAccessoryEntry"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalAccessory')){ return option.globalAccessory['name'] }} return option;}"
                    :clearable="false"
                    @input="onRollMosquitoNetGlobalAccessoryEntryChanged"
                    :placeholder="$t('empty')"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.rollMosquitoNetGlobalAccessoryEntry, 'thumbnail', 'globalAccessory')"
                    @open="openImage($event)"
                  )

              app-form-field(:label="$t('mosquito_net.global_net')" field="rollMosquitoNetGlobalNetEntry" horizontal expanded)
                b-field
                  v-select.w100.has-pointer(
                    :options="itemRollMosquitoNetGlobalNetEntries"
                    label="name"
                    v-model="item.rollMosquitoNetGlobalNetEntry"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalNet')){ return option.globalNet['name'] }} return option;}"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                  app-form-hover-image(
                    :image="image(item.rollMosquitoNetGlobalNetEntry, 'thumbnail', 'globalNet')"
                    @open="openImage($event)"
                  )

              b-field(grouped)
                app-form-field(:label="$t('additional')" field="rollMosquitoNetAdditional" horizontal expanded)
                  v-select.has-pointer.w100(
                    :options="asArray(item.rollMosquitoNet, 'rollMosquitoNetAdditionalEntries').filter(entry => entry.isEnabled)"
                    label="name"
                    v-model="item.rollMosquitoNetAdditionalEntries"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    @input="onRollMosquitoNetAdditionalChanged"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('rollMosquitoNetAdditional')){ return option.rollMosquitoNetAdditional['name'] }} return option;}"
                    multiple
                  )
                    template(v-slot:no-options='') {{$t('empty')}}

            .column
              app-form-field(
                :label="$t('order.width', { lengthUnit })"
                field="width"
                :help="widthHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.width"
                  :unit="lengthUnit"
                  @blur="onWidthChange"
                  @focus="showHelpForOrderDimensions = true"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.height', { lengthUnit })"
                field="height"
                :help="heightHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.height"
                  :unit="lengthUnit"
                  @focus="showHelpForOrderDimensions = true"
                  @blur="onHeightChange"
                  customClass="pl-1"
                )

              app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

              app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")
              b-field(grouped)
                app-form-field(
                  :label="$t('company_installation_value')"
                  field="companyInstallationValue"
                  horizontal
                  expanded
                )
                  b-field
                    v-select.w100.has-pointer(
                      :options="companyInstallationValuesBySelectedFabricShade"
                      label="name"
                      v-model="item.companyInstallationValue"
                      :clearable="false"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
            .column
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=2) {{ $t('order.total_net_value') }}
              tr
                th(v-if="!hideWholeSalePrice").has-text-centered {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td(v-if="!hideWholeSalePrice").has-text-right {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItem"
                :disabled="cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger" v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click="calculatePrice") {{ $t('refresh') }}
              //b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column.is-narrow
        .columns.is-centered
          .column.card-preview.has-min-width-large
            // Podgląd
            .columns
              .column.is-three-quarters-desktop.is-four-fifths-tablet(v-if="!getHideImage")
                app-view-card(:title="pictureTitle" class="has-margin-bottom" paddingless)
                  template(slot="color-picker-selector" v-if="!loading")
                    .columns.is-multiline.has-margin-top-very-small
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('window_color')" field="window_frame" expanded horizontal)
                            b-field
                              resource-select(
                                :value="windowFrame"
                                resource="window_frames"
                                url="window_frames"
                                extra-props="order[position]=asc"
                                :placeholder="$t('default_frame')"
                                @input="setWindowFrame"
                                nullable
                                expanded
                                disabled
                                full-object
                              )
                              b-button(icon-left="filter-remove" @click.prevent="resetWindowFrame")
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('wall_color')" field="wall_color" expanded horizontal)
                            b-field
                              input(type="color" v-model="backgroundColor" class="color-picker")

                  div
                    .card-div-preview(:style="{'backgroundColor': backgroundColor}")
                      .roll-mosquito-net(:style="windowFrameBackgroundImage")
                        .net.has-pointer(v-if="item.rollMosquitoNetGlobalNetEntry" @click="openImage(image(item.rollMosquitoNetGlobalNetEntry['globalNet'], 'picture'))"
                          :style="getBackgroundPictureWithContain(item.rollMosquitoNetGlobalNetEntry['globalNet'])")
                          .roll-border-top(:style="getBackgroundPictureWithContain(item.rollMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .roll-border-left
                            .roll-border-left-inside(:style="getBackgroundPictureWithContain(item.rollMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .roll-border-right
                            .roll-border-right-inside(:style="getBackgroundPictureWithContain(item.rollMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")
                          .roll-border-bottom(:style="getBackgroundPictureWithContain(item.rollMosquitoNetGlobalAccessoryEntry, 'globalAccessory')")



              br
              .column
                .columns.is-multiline.is-mobile
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('version')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.rollMosquitoNet, 'mechanismPicture')"
                                  :src="image(item.rollMosquitoNet, 'mechanismPicture')"
                                  width="76"
                                  @click="openImage(image(item.rollMosquitoNet, 'mechanismPicture'))"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('survey')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.rollMosquitoNet, 'surveyPicture')"
                                  :src="image(item.rollMosquitoNet, 'surveyPicture')"
                                  width="100"
                                  @click="openImage(image(item.rollMosquitoNet, 'surveyPicture'), item.rollMosquitoNet['surveyDescription'+getLocale])"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('files')")
                          a(
                            v-if="item.rollMosquitoNet && item.rollMosquitoNet.measurementInstruction"
                            class="button is-primary"
                            :href="item.rollMosquitoNet.measurementInstruction.contentUrl"
                            target="_blank"
                            download
                          ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import objectToCollection from "@/helpers/objectToCollection";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";
import debounce from "@/helpers/debounce";

export default {
  components: {ResourceSelect, AutomationFormModal},
  data() {
    return {
      globalError: null,
      errors: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      showHelpForOrderDimensions: false,
      isCalculated: false,
    };
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    ...mapActions("rollMosquitoNets", [
      "setMessage",
      "setIsMessageBorderAnimating",
      "toggleShowForm",
      "saveItem",
      "cancelEdit",
      "saveItemAndClose",
      "updateOrder",
      "updateClearOrder",
      "clearOrder",
    ]),
    ...mapMutations("rollMosquitoNets", ["setItem"]),
    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }
    },
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;


      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getBackgroundPictureWithCover(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          borderImage: "url(" + picture.contentUrl + ") 0 0 0 0 stretch stretch",
          background: "url(" + picture.contentUrl + ") 0% 0% / cover",
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    onQuantityChange() {
      const side = this.item.driveSide;
      if (side !== "P" || side !== "L" || side !== "L+P") {
        this.item.driveSide = "L";
      }
      this.calculatePrice();
    },
    onRollMosquitoNetChanged() {
      this.setMessageFromAccessory(this.item.rollMosquitoNet.message);
      if (this.itemRollMosquitoNetGlobalAccessoryEntries.find(entry => entry["@id"] === this.item.rollMosquitoNetGlobalAccessoryEntry["@id"] &&
          entry.globalAccessory["@id"] === this.item.rollMosquitoNetGlobalAccessoryEntry.globalAccessory["@id"]) === undefined) {
        this.item.rollMosquitoNetGlobalAccessoryEntry = this.itemRollMosquitoNetGlobalAccessoryEntries[0];
      }
      if (this.itemRollMosquitoNetGlobalNetEntries.find(entry => entry["@id"] === this.item.rollMosquitoNetGlobalNetEntry["@id"]
          && entry.globalNet["@id"] === this.item.rollMosquitoNetGlobalNetEntry.globalNet["@id"]) === undefined) {
        this.item.rollMosquitoNetGlobalNetEntry = this.itemRollMosquitoNetGlobalNetEntries[0];
      }

      this.calculatePrice();
    },

    isWidthValid() {
      this.removeError("width");

      if (this.item.width > this.maxWidth) {
        this.addError(
            "width",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.width < this.minWidth) {
        this.addError(
            "width",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      // if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz") {
      //   if (Math.abs(this.item.width - this.item.fabricWidth) < this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
      //     this.addError(
      //         "width",
      //         this.$t("not_smaller_than", {
      //           width: this.formatLength(this.item.fabricShade.fabricWidth),
      //           lengthUnit: this.lengthUnit
      //         })
      //     );
      //     return false;
      //   }
      // }
      //
      // if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o") {
      //   if (Math.abs(this.item.width - this.item.fabricWidth) > this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
      //     this.addError(
      //         "width",
      //         this.$t("smaller_than", {
      //           width: this.formatLength(this.item.fabricShade.fabricWidth),
      //           lengthUnit: this.lengthUnit
      //         })
      //     );
      //     return false;
      //   }
      // }

      return true;
    },

    onRollMosquitoNetGlobalAccessoryEntryChanged() {
      if(this.item.rollMosquitoNetGlobalAccessoryEntry && this.item.rollMosquitoNetGlobalAccessoryEntry["globalAccessory"].message) {
        this.setMessageFromAccessory(this.item.rollMosquitoNetGlobalAccessoryEntry["globalAccessory"].message);
      }
      this.calculatePrice();
    },
    onRollMosquitoNetAdditionalChanged() {
      this.calculatePrice();
    },

    onWidthChange() {
      this.showHelpForOrderDimensions = false;
      this.isWidthValid()
    },

    isHeightValid() {
      this.removeError("height");

      if (this.item.height > this.maxHeight) {
        this.addError(
            "height",
            this.$t("max_height_error", {
              height: this.formatLength(this.maxHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.height < this.minHeight) {
        this.addError(
            "height",
            this.$t("min_height_error", {
              height: this.formatLength(this.minHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isHeightValid();
    },

    setWindowFrame(newWindowFrame) {
      this.windowFrame = newWindowFrame;
    },
    resetWindowFrame() {
      this.windowFrame = null;
    },
    calculatePrice: debounce(async function () {
      if (
          this.item.width >= this.minWidth &&
          this.item.height >= this.minHeight
      ) {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/roll_mosquito_net_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message) {
      let existsError = this.errors.find(
          ({propertyPath}) => propertyPath === field
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message});
      }
    },
    removeError(field) {
      this.errors = this.errors.filter(
          ({propertyPath}) => field !== propertyPath
      );
    },

    handleItemWidthChange() {
      this.calculatePrice();
    },
    handleItemHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassHeightChange() {
      this.calculatePrice();
    },
    handleItemGlassWidthChange() {
      this.calculatePrice();
    },

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },
  },
  /**
   * Computed properties
   */
  computed: {
    orderHasAtLeastOneItem() {
      return this.order.rollMosquitoNetOrderItems && this.order.rollMosquitoNetOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedFabricShade() {
      if (!this.item.rollMosquitoNet) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.rollMosquitoNets &&
              civ.rollMosquitoNets.findIndex(
                  fs => fs === this.item.rollMosquitoNet["@id"]
              ) > -1
      );
    },

    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    windowFrameBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else if (
          this.item.width > this.maxWidth ||
          this.item.height > this.maxHeight
      ) {
        return true;
      } else if (this.errors.length > 0) {
        return true
      }
      return false;
    },
    getHideImage() {
      return this.hideImage
    },
    ...mapGetters("rollMosquitoNets", [
      "order",
      "showForm",
      "image",
      "rollMosquitoNets",
      "itemRollMosquitoNet",
      "itemRollMosquitoNetGlobalAccessoryEntries",
      "itemRollMosquitoNetGlobalNetEntries",
      "item",
      "isEdit",
      "message",
      "isMessageBorderAnimating",
      "changeStatusesForEditItem",
    ]),
    ...mapGetters(["locales", "hideImage", "hideWholeSalePrice"]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice", "yesNoOptions"]),
    pictureTitle() {
      if (this.item.rollMosquitoNet) {
        return this.$t("picture_review", {
          rollMosquitoNetName: this.item.rollMosquitoNet.name
        });
      }
      return " ";
    },
    widthHelp() {
      const showMax = this.maxWidth !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    heightHelp() {
      const showMax = this.maxHeight !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minHeight)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxHeight)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    minWidth() {
      return this.item.rollMosquitoNet && this.item.rollMosquitoNet.minWidth;
    },
    maxWidth() {
      const maxWidth = this.item.rollMosquitoNet && this.item.rollMosquitoNet.maxWidth;
      if (maxWidth) {
        return maxWidth;
      }
      return Number.MAX_SAFE_INTEGER;
    },
    maxHeight() {
      return this.item.rollMosquitoNet && this.item.rollMosquitoNet.maxHeight;
    },
    minHeight() {
      return this.item.rollMosquitoNet && this.item.rollMosquitoNet.minHeight;
    },

  },
  /**
   * Watchers
   */
  watch: {
    "item.width": "handleItemWidthChange",
    "item.height": "handleItemHeightChange",
    companyInstallationValuesBySelectedFabricShade(values) {
      if (values.length > 0) {
        this.item.companyInstallationValue = values[0];
      }
    },
  }
};
</script>
